@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&display=swap');

.ppolicybody-section {
    display: flex;
    /* width: 1792px; */
    padding: 50px 10%;
    flex-direction: column;
    align-items: center;
    gap:30px;
}


.ppolicybody-section .tab-section {
    display: grid;
    grid-template-columns: 150px calc(100% - 150px);
    gap:50px;
}

.ppolicybody-section p {
    font-family: Maven Pro;
    font-size: 20px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #121416;   
  }

  .ppolicybody-section ul {
    list-style-image: url('../images/pp-points.svg');
  }

  .ppolicybody-section ul li {
    font-family: Maven Pro;
    font-size: 20px;
    font-weight: 500;
    line-height: 33px;
    text-align: left;
    color: #121416;   
  }


  .ppolicybody-section  .tabdiv {
    overflow-y: scroll;
    height: 100%;
    scrollbar-width: none !important;
    -ms-overflow-style: none;

  }
  ::-webkit-scrollbar {
    display: none;
}


.ppolicybody-section h2 {
    font-family: Maven Pro;
    font-size: 35px;
    font-weight: 500;
    line-height: 41.13px;
    text-align: left;
    color: #121416;
}


.ppolicybody-section h4 {
    font-family: Maven Pro;
    font-size: 20px;
    font-weight: 500;
    line-height: 33px;
    text-align: left;
    color: #121416;
}


/* Style the tab */
.ppolicybody-section .tab {
    float: left;
    /* border: 1px solid #ccc; */
    width: 100%;
    /* height: 300px; */
  }
  
  /* Style the buttons inside the tab */
  .ppolicybody-section .tab button {
    display: block;
    padding: 10px 16px;
    width: 100%;
    display: flex;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    border-left: 1px solid #E5E5E5;
    background: #ffffff;

    text-wrap: nowrap;
    overflow: hidden;
  }

  .ppolicybody-section .tab button span {
    font-family: Maven Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.8px;
    text-align: left;
    color: #838383;
   
  }

  
.ppolicybody-section .tab button:hover ,
.ppolicybody-section .tab button.active{
    border-left:4px solid #FFC600;
}

.ppolicybody-section .tab button:hover span,
.ppolicybody-section .tab button.active span{
  color: #121416;
  font-weight: 600;
}

  
  /* Style the tab content */
  .ppolicybody-section .tabcontent {
    display: none;
    text-align: left;
    padding: 0px 12px;
    width: 100%;
    border-left: none;
    height: auto;
    flex-direction: column;
    gap:10px;
  }


  .ppolicybody-section .tabcontent.active {
    display: flex;
  } 


@media only screen and (min-width : 320px)  and (max-width : 1024px)  { 
  .ppolicybody-section {
    padding: 50px 20px;
  }

  .ppolicybody-section .tab button span {
    font-size: 12px;
    line-height: 14.1px;    
  }

  
.ppolicybody-section p {
    font-size: 12px;
    line-height: 18px;
  }

  .ppolicybody-section ul {
    list-style-image: url('../images/pp-points.svg');
  }

  .ppolicybody-section ul li {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;   
  }



.ppolicybody-section h2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
}


.ppolicybody-section .tab-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.ppolicybody-section .tab {
    display: flex;
    width: 100%;
    overflow-x: scroll;
}

 /* Style the buttons inside the tab */
 .ppolicybody-section .tab button {
    display: block;
    padding: 10px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #E5E5E5;
    text-wrap: nowrap;
    overflow: unset;
  }

  .ppolicybody-section .tab button span {
    font-size: 12px;
    line-height: 14.1px;   
  }

  
.ppolicybody-section .tab button:hover ,
.ppolicybody-section .tab button.active{
    border: none;
    border-bottom:4px solid #FFC600;
}

.ppolicybody-section .tab button:hover span,
.ppolicybody-section .tab button.active span{
  color: #121416;
  font-weight: 600;
}

}
