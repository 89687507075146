.our-products {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:50px;
    
    background: url('../images/our-product-bg.gif') no-repeat;
    background-position: top center;
    background-size: cover;
    position: relative;
    min-height: 100vh;
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.solution-product.our-products {
    background: none;
}


.our-products video#product-background-video {
    width: 100%;
    position: absolute;
    object-fit: cover;
    height: 100%;
    min-height: 100vh;
    z-index: -1;
    top: 0;
    left: 0;
  
  }
  

.our-products .product-sec {
    background: #ffffff;
    border-radius: 40px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 50% 50%;
    gap:0px;
}

.our-products .product-sec .left {
    padding:75px 50px 75px;
    padding: 40px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:20px;
}

.solution-product.our-products .product-sec .left {
    gap:15px;
}

.our-products .product-sec .left h3 {
    font-family: Manrope;
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.004em;
    text-align: left;
    color: #141616;
}

.our-products .product-sec .left h4 {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    line-height: 33.6px;
    letter-spacing: 0.004em;
    text-align: left;
    color: #141616;
}


.our-products .product-sec .left p ,
.our-products .product-sec .left ul li {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.004em;
    text-align: left;
    color: #141616;
}

.our-products .product-sec .right {
    /* background: url('../images/our-product.webp') no-repeat; */
    background-position: top center;
    background-size: cover;
    overflow: hidden;
    border-radius: 0 40px 40px 0;
}

.our-products .product-sec .right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* display: none; */
}

.our-products .product-sec .right iframe {
    width: 100%;
    height: 100%;
}

.our-products a.button {
    font-size: 20px;
    line-height: 14px;
    padding: 20px 40px;
}


@media only screen  and (max-width : 767px)  {
    .our-products  {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .our-products .product-sec {
        grid-template-columns: 100%;
        width: 90%;
        margin: auto;
    }


    .our-products .product-sec .left {
        padding: 25px 30px 25px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .our-products .product-sec .left h3 {
        font-size: 30px;
        line-height: 36px;
    }


    .our-products .product-sec .left p { 
        font-size: 14px;
        line-height: 19.6px;
    }

    .our-products .product-sec .right {
        border-radius: 0 0px 40px 40px;
    }

    .our-products .product-sec .right img {
        width: 100%;
        /* display: none; */
    }

    .our-products .product-sec .right video {
        width: 100%;
        height: 100%;
        display: none;
        object-fit: cover;
    }

    .our-products .product-sec .right iframe {
        width: 100%;
        height: 100%;
        min-height: 313px;
    }
}


@media only screen and (min-width: 768px) and (max-width: 1024px) { 
    .our-products  {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .our-products .product-sec {
        grid-template-columns: 100%;
        width: 90%;
        margin: auto;
    }


    .our-products .product-sec .left {
        padding: 25px 30px 25px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .our-products .product-sec .left h3 {
        font-size: 30px;
        line-height: 36px;
    }


    .our-products .product-sec .left p { 
        font-size: 14px;
        line-height: 19.6px;
    }

    .our-products .product-sec .right {
        border-radius: 0 0px 40px 40px;
    }

    .our-products .product-sec .right img {
        width: 100%;
        /* display: none; */
    }

    .our-products .product-sec .right video {
        width: 100%;
        height: 100%;
        display: none;
        object-fit: cover;
    }

    .our-products .product-sec .right iframe {
        width: 100%;
        height: 100%;
        min-height: 313px;
    }
}