.book-demo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:50px;
    background: url('../images/book-demo.webp') no-repeat;
    background-position: top center;
    background-size: cover;
    min-height: 100vh;  
    padding-top: 50px;
    padding-bottom: 50px;
}


.book-demo .top-box {
    width: 90%;
    margin: auto;
    text-transform: capitalize;
}

.book-demo h2.main-heading {
    font-family: Manrope;
    font-size: 38px;
    font-weight: 600;
    line-height: 57px;
    text-align: center;
    color:#ffffff;
}

.book-demo p {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #999999;
}


.book-demo .book-form {
    max-width: 700px;
    margin:50px auto;
}


.book-demo .book-form input,
.book-demo .book-form textarea {
    width: 100%;
    height: 55px;
    font-family: Manrope;
    font-size: 20px;
    font-weight: 500;
    line-height: 27.32px;
    letter-spacing: -0.01em;
    text-align: left;
    color:#9CA0AA;
    padding: 15px;
    opacity: 1;
}

::placeholder {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 500;
    line-height: 27.32px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #9CA0AA;

}

.book-demo .book-form select,
.book-demo .book-form select option {
    width: 100%;
    height: 55px;
    font-family: Manrope;
    font-size: 20px;
    font-weight: 500;
    line-height: 27.32px;
    letter-spacing: -0.01em;
    text-align: left;
    color:#9CA0AA;
    opacity: 1;
    border-radius: 0px;
}


.book-demo .book-form textarea {
    height: 200px;
}



.book-demo .book-form button[type="submit"] {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    color:#151522;
    padding: 20px 40px;
    background: #EEAE21;
    width: 100%;
    border-radius: 2px;
    box-shadow: none;
    border: none;
}



.book-demo .book-form .query-box {
    position: relative;
}

.book-demo .book-form .query-box::after {
    position: absolute;
    right: 18px;
    top: 27.5px;
    content: "";
    z-index: 3;
    width: 0px;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 14px solid #292d32;
    transform: translateY(-50%) rotate(90deg);
}

.book-demo .book-form .query-box.select::after {
    transform: translateY(-50%) rotate(-90deg);
}


@media only screen and (max-width : 767px)  
{
    .book-demo {
        padding-top: 50px;
    }

    .book-demo .container {
        width: 90%;
        margin: auto;
    }

    .book-demo h2.main-heading {
        font-size: 26px;
        line-height: 33.8px;
        max-width: 300px;
        margin: auto auto 10px auto;

    }

    .book-demo p {
        font-size: 14px;
        line-height: 21px;
        max-width: 300px;
        margin: auto;
    }

    ::placeholder {
        font-size: 14px;
        line-height: 19.12px;
    }


    .book-demo .book-form select, .book-demo .book-form select option {
        font-size: 14px;
        line-height: 19.12px;
    }
}


@media only screen and (min-width: 768px) and (max-width: 1024px) { 
    .book-demo {
        padding-top: 50px;
    }

    .book-demo .container {
        width: 90%;
        margin: auto;
    }

    .book-demo h2.main-heading {
        font-size: 26px;
        line-height: 33.8px;
        max-width: 400px;
        margin: auto auto 10px auto;

    }

    .book-demo p {
        font-size: 14px;
        line-height: 21px;
        max-width: 500px;
        margin: auto;
    }

    ::placeholder {
        font-size: 14px;
        line-height: 19.12px;
    }


    .book-demo .book-form select, .book-demo .book-form select option {
        font-size: 14px;
        line-height: 19.12px;
    }
}