.whyrepliq-banner {
    width: 100%;
    min-height: calc( 100vh - 108px );
    position: relative;
    /* background: url('../images/home_banner.webp') no-repeat top; */
    background-position: top left;
    background-size: cover;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 10%;
    display: flex;
    gap:30px;
    flex-direction: column;
    justify-content: end;
    align-items: flex-start;
    position: relative;
    text-transform: capitalize;
    background: linear-gradient(248.26deg, rgba(0, 0, 0, 0) 18.18%, rgba(0, 0, 0, 0.9) 84.47%), url('../images/whyrepliq_banner.webp') no-repeat top center;;
}


.whyrepliq-banner video#background-video {
  width: 100%;
  position: absolute;
  object-fit: cover;
  height: calc( 100vh - 108px );
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;

}


.whyrepliq-banner .text-box h2 {
    font-family: Manrope;
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0.004em;
    text-align: left;
    color:#ffffff;  
}

  
@media only screen and (max-width : 767px) {

  .whyrepliq-banner {
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 50px;
    padding-bottom: 150px;
    min-height: calc( 100vh - 50px );
    background: linear-gradient(11.01deg, rgba(0, 0, 0, 0) 30.39%, rgba(0, 0, 0, 0.8) 58.22%), url('../images/whyrepliq_banner_mb.webp') no-repeat center center;  
    background-size: cover;
  } 

  .whyrepliq-banner video#background-video {
    left: unset;
    right: 0;
    object-fit: none;
  }


  .whyrepliq-banner .text-box h2 {
      font-size: 40px;
      line-height: 48px;
      text-align: left;
      margin-bottom: 30px;
  }

}




@media only screen and (min-width: 768px) and (max-width: 1024px) { 

  
  .whyrepliq-banner {
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 50px;
    padding-bottom: 150px;
    min-height: calc( 100vh - 50px );
    background: linear-gradient(11.01deg, rgba(0, 0, 0, 0) 30.39%, rgba(0, 0, 0, 0.8) 58.22%), url('../images/whyrepliq_banner_mb.webp') no-repeat center center;  
    background-size: cover;
  }

  .whyrepliq-banner .text-box h2 {
    font-size: 50px;
    line-height: 1.2em;
    margin-bottom: 20px;
}


}