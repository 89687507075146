.header-banner {
    width: 100%;
    min-height: calc( 100vh - 108px );
    position: relative;
    /* background: url('../images/home_banner.webp') no-repeat top; */
    background-position: top left;
    background-size: cover;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 10%;
    display: flex;
    gap:30px;
    flex-direction: column;
    justify-content: end;
    align-items: flex-start;
    position: relative;
    text-transform: capitalize;
    background: linear-gradient(246.94deg, rgba(0, 0, 0, 0) -41.18%, rgba(0, 0, 0, 0.5) 94%);
}


.solutions-banner .desktop {
  display: none;
}

.solutions-banner .mobile {
  display: block;
}

.header-banner video#background-video {
  width: 100%;
  position: absolute;
  object-fit: cover;
  height: calc( 100vh - 108px );
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;

}


.header-banner .text-box h2 {
    font-family: Manrope;
    font-size: 140px;
    font-weight: 500;
    line-height: 154px;
    text-align: left;
    color:#ffffff;
    margin-bottom: 30px;
}

.header-banner .text-box h5 {
    font-family: Manrope;
    font-size: 60px;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: 0.004em;
    text-align: left;
    color:#ffffff;
    margin-bottom: 20px;
}


/* Scroller styling */
.header-banner .scroller {
    height: 1.2em;
    line-height: 1.2em;
    height: 140px;
    line-height: 140px;
    position: relative;
    overflow: hidden;
  }

  .header-banner .scroller > h2 {
    position: absolute;
    top: -25px;
    animation: slide 10s infinite;
    animation-delay: 2s;
    font-weight: bold;
  }
  @keyframes slide {
  
    0% {
      top: -25px;
    }
    30% {
      top: -170px;
    }
    40% {
      top: -170px;
    }
    60% {
      top: -320px;
    }
    
  }



  
@media only screen and (max-width : 767px)  {

  .header-banner {
    min-height: calc( 100vh - 50px );
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 50px;
    padding-bottom: 150px;
    background: linear-gradient(246.94deg, rgba(0, 0, 0, 0) -41.18%, rgba(0, 0, 0, 0.5) 94%);

}


.header-banner video#background-video {
  left: unset;
  right: 0;
  object-fit: none;
}


.header-banner .text-box h2 {
    font-size: 60px;
    line-height: 66px;
    text-align: left;
    margin-bottom: 30px;
}

.header-banner .text-box h5 {
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 20px;
}


/* Scroller styling */
.header-banner .scroller {
  height: 60px;
  line-height: 60px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.header-banner .scroller > h2 {
  position: absolute;
  top: -10px;
  animation: slide 10s infinite;
  animation-delay: 2s;
  font-weight: bold;
}
@keyframes slide {

  0% {
    top: -10px;
  }
  30% {
    top: -73px;
  }
  40% {
    top: -73px;
  }
  60% {
    top: -140px;
  }
  
}

}

@media only screen and (min-width: 768px) and (max-width: 1024px) { 

  
  .header-banner {
    min-height: calc( 100vh - 100px );
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 50px;
    padding-bottom: 150px;
    background: linear-gradient(246.94deg, rgba(0, 0, 0, 0) -41.18%, rgba(0, 0, 0, 0.5) 94%);
  }

  .header-banner .text-box h2 {
    font-size: 80px;
    line-height: 1.2em;
    margin-bottom: 20px;
}

.header-banner .text-box h5 {
    font-size: 50px;
    line-height: 1.2em;
    margin-bottom: 15px;
}


/* Scroller styling */
.header-banner  .scroller {
  height: 1.2em;
  line-height: 1.2em;
  height: 80px;
  line-height: 80px;
  position: relative;
  overflow: hidden;
}

.header-banner .scroller > h2 {
  position: absolute;
  top: -15px;
  animation: slide1 10s infinite;
  /* animation:none !important; */
  animation-delay: 2s;
  font-weight: bold;
}

@keyframes slide1 {

  0% {
    top: -15px;
  }
  30% {
    top: -100px;
  }
  40% {
    top: -100px;
  }
  60% {
    top: -200px;
  }
  
}



}