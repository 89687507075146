.ppolicy-banner {
    width: 100%;
    min-height: 350px;
    position: relative;
    background-position: top left;
    background-size: cover;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    background: linear-gradient(248.26deg, rgba(0, 0, 0, 0) 18.18%, rgba(0, 0, 0, 0.9) 84.47%) ,  url('../images/privacypolicy_banner.webp') no-repeat center center;
}


.ppolicy-banner .text-box h2 {
    font-family: Manrope;
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0.004em;
    text-align: left;
    color:#ffffff;
}




  
@media only screen and (max-width : 767px)  {

  .ppolicy-banner {
    display: flex;
    gap: 30px;
    min-height: 250px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 50px;
    padding-bottom: 50px;
    background: linear-gradient(3.19deg, rgba(0, 0, 0, 0) -13.35%, rgba(0, 0, 0, 0.8) 36.68%) , url('../images/privacypolicy_banner_mb.webp') no-repeat center center;
    background-size: cover;
}



.ppolicy-banner .text-box h2 {
  font-size: 40px;
  line-height: 48px;
  max-width: 150px;
}




}


