.header {
    background: #2C2B2C;
    width: 100%;
    height: auto;
    padding-left: 100px;
    padding-right: 100px;
}


.header .navbar-brand img {
    max-width: 166px;
}

.header .navbar-brand ,
.header .navbar {
    padding-top: 0px;
    padding-bottom: 0px;
} 


.header .navbar .nav-link,
.header .navbar .nav-item .nav-link a {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 27.3px;
    text-align: center;
    color:#ffffff;
}

.nav-link:focus-visible {
    outline: 0;
    box-shadow: none;
}

.navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
}

.manu-btn {
    letter-spacing: 0.64px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.navbar-toggler {
    border: none;
}

.navbar-toggler-icon {
    background-image: url('../images/humburger.svg');
}

.navbar-toggler:focus {
    box-shadow: none;
    border: none;
}

@media only screen and (max-width : 767px)  {

    .header {
        padding: 5px 5px;
        max-height: 50px;
    }

    .navbar-toggler {
        padding: 0.25rem 0rem;
    }
    
    .navbar-toggler-icon {
        width: 18px;
    }
    
    .offcanvas {
        background: #000000;
        color: #ffffff;
    } 
    
    .manu-btn {
        display: inline-block;
        align-items: center;
        width: auto;
        margin-top: 15px;
    }
    
    .btn-close {
        --bs-btn-close-color:#ffffff;
        /* background: #ffffff; */
        --bs-btn-close-bg:url('../images/close-circle.svg');
        background-image: url('../images/close-icon.svg');
        fill: white;
        background-size: 100%;
    }

}



@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .header {
        padding-left: 15px;
        padding-right: 15px;
    }

    .navbar-toggler {
        padding: 0.25rem 0rem;
    }
    
    .navbar-toggler-icon {
        width: 18px;
    }
    

    .offcanvas {
        background: #000000;
        color: #ffffff;
    } 
    

    .manu-btn {
        display: inline-block;
        align-items: center;
        width: auto;
        margin-top: 15px;
        margin-left: 0px;
    }
    
    .btn-close {
        --bs-btn-close-color:#ffffff;
        /* background: #ffffff; */
        --bs-btn-close-bg:url('../images/close-circle.svg');
        background-image: url('../images/close-icon.svg');
        fill: white;
        background-size: 100%;
    }

}