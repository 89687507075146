.core-values {
    padding-top: 50px;
    padding-bottom: 150px;
  }
  
  .core-values .top-section{
    text-align: center;
    position: absolute;
    left: 0px;
    top:50px;
    padding-bottom: 50px;
    width: 100vw;

  }

  .core-values .top-section h2.main-heading ,
  .core-values .top-section h4.sub-heading {
    text-align: center;
  }


  .core-values .top-section p {
    font-family: Manrope;
    font-size: 22px;
    font-weight: 400;
    line-height: 26.4px;
    letter-spacing: 0.004em;
    text-align: center;
    color: #000000;
  }

 
.main-container {
  overscroll-behavior: none;
  width: 150%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding-left: 200px !important;
  overflow-x: hidden;
}

.panel.align-top {
  align-items: flex-start;
}


.core-values .panel {
  width: 45%;
  max-width: 450px;
  height: calc( 100vh - 150px );
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.5em;
  text-align: center;
  color: white;
  position: relative;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 100px;
}


.core-values .panel img.bottom-border {
    width: 100%;
    margin-right: 15px;
    transform: translate(50%, 20px);
    display: block;
}

.core-values .panel:last-child img.bottom-border {
    height: 22px;
    transform: translate(0, 20px);
}

.core-values section .container {
  margin: auto;
}


.core-values .value {
    position: relative;
    margin-top: 50px;
    width: 100%;
    height: auto;
    cursor: pointer;
    transition: all .2s ease-in;
}

.core-values .value .heading {
    position: absolute;
    top:50px;
    left: 50%;
    transform: translateX(-50%);
    font-family: Manrope;
    font-size: 29.25px;
    font-weight: 700;
    line-height: 35.1px;
    letter-spacing: 0.004em;
    text-align: center;
    color: #141616;
    transition: all .2s ease-in;

}

.core-values .value .hoverimg {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0px;
    top: 0px;
    z-index: 2;
    object-fit: cover;
}



 .core-values .value .hoverimg {
    width: 100%;
    height: auto;
    opacity: 0;
    transition: all .5s ease-in;
}

.core-values .value:hover .img {
   width: 100%;
   height: auto;
   opacity: 0;
   transition: all .2s ease-in;
}

 .core-values .value .img {
    width: 100%;
    height: auto;
    opacity: 1;
    transition: all .2s ease-in;
}


.core-values .value:hover .hoverimg {
   width: 100%;
   height: auto;
   opacity: 1;
   transition: all .5s ease-in;
}

.core-values .value:hover .heading {
    color:#ffffff;
    z-index: 5;
    transition: all .2s ease-in;
}





@media only screen  and (max-width : 1680px)  {
  
  
  .core-values .top-section {
    padding-right: 5%;
    padding-left: 5%;
    left: 40%;
    transform: translateX(-60%);  
    left: 25%;
    transform: translateX(-50%);
  }
  

  .core-values .solution {
    display: flex;
    gap:30px;
  }

  .core-values .panel {
    width: 45%;
    max-width: 400px;
    height: calc( 100vh - 100px );
  }
 
}



@media only screen and (max-width : 1367px)  {

    .core-values {
      padding-top: 0px;
      padding-bottom: 100px;
    }

    .core-values .top-section {
      top:20px;
    }
    
    .main-container {
      padding-left: 100px !important;
      width: 200%;
    }
    
    .core-values .panel {
      width: 35%;
      max-width: 280px;
      height: calc(100vh - 100px);
      margin-top: 50px;
    }
}

/* 
.core-values .value {
  display: flex;
  gap:70px;
}

.core-values .value .left {
  width: 500px;
  text-align: left;
  background: #F5F5F5;
  border-radius: 40px;
  padding: 30px 40px;
  padding-top: 50px;
}

.core-values .value .right {
  width: 630px;
  border-radius: 40px;
}

.core-values .value .right img {
  width: 100%;
  border-radius: 40px;
}


.core-values section.panel .container {
  margin-left: 25%;
}

.core-values .value .left span {
  font-family: Manrope;
  font-size: 24px;
  font-weight: 300;
  line-height: 28.8px;
  letter-spacing: 0.004em;
  text-align: left;
  color: #141616;
  padding: 20px;
  border-radius: 20px;
  background: #E8E8E8;
}

.core-values .value .left h2 {
  font-family: Manrope;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0.004em;
  text-align: left;
  color: #141616;
  margin-bottom: 15px;
  margin-top: 35px;
}


.core-values .value .left h4 {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.004em;
  text-align: left;
  color: #141616;
  margin-bottom: 15px;
}

.core-values .value .left p,
.core-values .value .left a {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.004em;
  text-align: left;
  color: #141616;
  margin-bottom: 15px;
}

.core-values .value .left a img {
  padding-left: 5px;
  width: 28px;
} */


@media only screen and (max-width : 768px)  {

 .core-values {
    width: 100%;
    margin: auto;
    padding-top: 25px;
    padding-bottom: 25px;
  }

 .core-values .top-section {
    position: unset;
    text-align: center;
    width: 90%;
    margin: auto;
    padding: 0px;
    transform: none;
  }

 .core-values .top-section h2.main-heading {
    width: 70%;
    margin: auto;
    margin-bottom: 10px;
    font-size: 40px;
    line-height: 48px;
  }

  

  .core-values .panel {
    width: 100%;
    max-width: unset;
    height: auto;
    margin-top: 0px;
  }

 .core-values .value {
    flex-direction: column;
    gap:0px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  
  .core-values .value .heading {
    top: 25px;
    font-size: 22.11px;
    line-height: 26.53px;
  }


  .core-values .value:hover .heading {
    color: #141616;
  }

  .core-values .value .img {
    width: 100%;
    height: auto;
    border-radius: 25px;
  }

  .core-values .value:hover .img {
    opacity: 1;
  }

}


@media only screen and (min-width: 768px) and (max-width: 1024px) {   

  .core-values.desktop {
    display: none;
  }

  .core-values.mobile {
    display: block;
  }
  
 .core-values {
  width: 100%;
  margin: auto;
  padding-top: 25px;
  padding-bottom: 25px;
}

.core-values .top-section {
  position: unset;
  text-align: center;
  width: 90%;
  margin: auto;
  padding: 0px;
  transform: none;
}

.core-values .top-section h2.main-heading {
  width: 70%;
  margin: auto;
  margin-bottom: 10px;
  font-size: 40px;
  line-height: 48px;
}

.core-values .panel {
  width: 100%;
  max-width: unset;
  height: auto;
  margin-top: 0px;
}

.core-values .value {
  flex-direction: column;
  gap:0px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.core-values .value .heading {
  top: 25px;
  font-size: 22.11px;
  line-height: 26.53px;
}

.core-values .value .img {
  width: 100%;
  height: auto;
  border-radius: 25px;
}
}