.whychoose {
    position: relative;
    margin-top: 120px;
    margin-bottom: 120px;
  }
  
  .whychoose .top-section {
    text-align: center;
    width: 80%;
    margin: auto;
  }

  
  .whychoose .top-section h4 {
    font-family: Manrope;
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0.004em;
    text-align: center;
    color: #141616;
    margin-bottom: 20px;

  }

  .whychoose .top-section p {
    font-family: Manrope;
    font-size: 32px;
    font-weight: 400;
    line-height: 41.6px;
    letter-spacing: 0.004em;
    text-align: center;    
    color: #141616;
  }

@media only screen and (max-width : 767px) {

  .whychoose {
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .whychoose .top-section h4 {
    font-size: 40px;
    line-height: 48px;    
  }


  .whychoose .top-section p {
    font-size: 16px;
    line-height: 22.4px;
  }

}


@media only screen and (min-width: 768px) and (max-width: 1024px) {  
  
  .whychoose {
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .whychoose .top-section h4 {
    font-size: 40px;
    line-height: 48px;    
  }


  .whychoose .top-section p {
    font-size: 16px;
    line-height: 22.4px;
  }
}