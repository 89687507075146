.wp-applications {
    padding-top: 100px;
}

.wp-applications .top-section {
    width: 80%;
    margin: auto auto 65px;
}

.wp-applications .top-section .main-heading ,
.wp-applications .top-section .sub-heading {
    padding-bottom: 25px;
}

.wp-applications .top-section h3 {
    font-family: Manrope;
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.004em;
    text-align: center;
    color: #141616;
}

.wp-applications .hover-boxes {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    justify-content: center;
    gap:30px;
}

.wp-applications .hover-box {
    position: relative;
    border-radius: 40px;
    overflow: hidden;
    cursor: pointer;
}

.wp-applications .hover-box:hover .hover-img {
    transform: scale(1.5);
}

.wp-applications .hover-boxes .hover-img {
    width: 100%;
    transition: all .5s ease-in;
}

.wp-applications .hover-box .hover-text,
.wp-applications .hover-box h4.title {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}

.wp-applications .hover-box h4.title {
    top: 25px;
    width: 80%;
}

.wp-applications .hover-box .hover-text {
    position: absolute;
    bottom: 50px;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    background: #ffffff;
    padding: 20px 25px;
    border-radius: 20px;
    transition: all .5s ease-in;
    box-shadow: 0px 2px 4px 0px #0000001A;
    opacity: 0;
}

.wp-applications .hover-box:hover .hover-text {
    opacity: 1  ;
}


.wp-applications .hover-box h4 {
    font-family: Manrope;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0.004em;
    text-align: center;
}

.wp-applications .hover-box .hover-text h4 {
    font-family: Manrope;
    font-size: 32px;
    font-weight: 600;
    line-height: 38.4px;
    letter-spacing: 0.004em;
    text-align: center;
    color: #141616;
}

.wp-applications .hover-box .hover-text a {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: center;
    color:#000000;
    margin: auto;
    float: unset;

}

.wp-applications .hover-box .hover-text a img {
    padding-left: 20px;
}

.wp-applications a.button {
    font-size: 20px;
    line-height: 14px;
    padding: 20px 40px;
}

.modal-applications .modal-dialog {
    width: calc(100vw - 50px);
    width: 100%;
    margin: auto;
    padding: 0px 25px;
    max-width: unset;
}

.modal-applications .modal-dialog .modal-header {
    border: none;
    position: absolute;
    right: 15px;
    top: 15px;
}

.modal-header .btn-close {
  background:url('../images/close-circle.svg');
  background-size: 40px;
  padding: 0px;
  width: 40px;
  height: 40px;
  opacity: 1;
  box-shadow: none;
}

@media only screen  and (max-width : 767px)  {
    
    .wp-applications {
        padding-top: 50px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .wp-applications .top-section {
        width: 100%;
        margin: auto auto 15px;
    }

    .wp-applications .top-section .main-heading {
        font-size: 40px;
        line-height: 48px;
        padding-bottom: 15px;
    }

    .wp-applications .top-section h3 {
        font-size: 18px;
        line-height: 25.2px;
        font-weight: 600;
    }

    .wp-applications .top-section .sub-heading {
        font-size: 16px;
        line-height: 22.4px;
    }

    .wp-applications .hover-boxes {
        display: grid;
        grid-template-columns: 100%;
        justify-content: center;
        gap:50px;
    }

    .wp-applications .hover-box .hover-text {
        width: auto;
        min-width: 200px;
        opacity: 1;
        bottom: 20px;
    }

    .wp-applications .hover-box,
    .wp-applications .hover-box .hover-text { 
        border-radius: 22.11px;
    }
    
    .wp-applications .hover-box:hover .hover-img {
        transform: none;
    }

    .wp-applications .hover-box h4 {
        font-size: 28.58px;
        line-height: 34.29px;
     }

     .wp-applications .hover-box .hover-text a {
        font-size: 16px;
        line-height: 22.4px;
    }

    .wp-applications .hover-box .hover-text a img {
        height: 15px;
    }

    .modal-applications .modal-dialog {
        padding: 0px 0px;
    }

    .modal-header .btn-close {
        opacity: 0.5;
    }

}


@media only screen and (min-width: 768px) and (max-width: 1024px) { 
    .wp-applications {
        padding-top: 50px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .wp-applications .top-section {
        width: 100%;
        margin: auto auto 15px;
    }

    .wp-applications .top-section .main-heading {
        font-size: 40px;
        line-height: 48px;
        padding-bottom: 15px;
    }

    .wp-applications .top-section h3 {
        font-size: 18px;
        line-height: 25.2px;
        font-weight: 600;
    }

    .wp-applications .top-section .sub-heading {
        font-size: 16px;
        line-height: 22.4px;
    }

    .wp-applications .hover-boxes {
        display: grid;
        grid-template-columns: 100%;
        justify-content: center;
        gap:50px;
    }

    .wp-applications .hover-box .hover-text {
        width: auto;
        min-width: 200px;
        opacity: 1;
        bottom: 20px;
    }

    .wp-applications .hover-box,
    .wp-applications .hover-box .hover-text { 
        border-radius: 22.11px;
    }
    
    .wp-applications .hover-box:hover .hover-img {
        transform: none;
    }

    .wp-applications .hover-box h4 {
        font-size: 28.58px;
        line-height: 34.29px;
     }

     .wp-applications .hover-box .hover-text a {
        font-size: 16px;
        line-height: 22.4px;
    }

    .wp-applications .hover-box .hover-text a img {
        height: 15px;
    }

    .modal-applications .modal-dialog {
        padding: 0px 0px;
    }

    .modal-header .btn-close {
        opacity: 0.5;
    }

}