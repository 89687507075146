.our-solution {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .our-solution .top-section {
    text-align: center;
    position: absolute;
    padding-right: 10%;
    width: 100vw;

    padding-right: 5%;
    padding-left: 5%;
    left: 40%;
    transform: translateX(-60%);
  }

  .our-solution .top-section h2.main-heading ,
  .our-solution .top-section h4.sub-heading {
    text-align: center;
  }

  .our-solution .top-section p {
    font-family: Manrope;
    font-size: 22px;
    font-weight: 400;
    line-height: 26.4px;
    letter-spacing: 0.004em;
    text-align: center;
    color: #000000;
  }

 
.main-container {
  overscroll-behavior: none;
  width: 250%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
}

.our-solution section .container {
  max-width: unset;
}

.panel.align-top {
  align-items: flex-start;
}


.panel {
  width: auto;
  height: calc( 100vh - 200px );
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.5em;
  text-align: center;
  color: white;
  position: relative;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 200px;

}

.main-container .panel:first-child .solution{
  margin: 10%;
}

.our-solution section .container {
  margin: auto;
}

.our-solution .bottom-border {
  padding-top: 15px;
}

.our-solution .solution {
  display: flex;
  align-items: flex-start;
  gap:70px;
}

.our-solution .solution .left {
  width: 650px;
  text-align: left;
  /* display: flex;
  flex-direction: column;
  gap: 15px; */
  background: #F5F5F5;
  border-radius: 40px;
  padding: 30px 40px;
  padding-top: 50px;
  min-height: 450px;
}

.our-solution .solution .right {
  width: 630px;
  border-radius: 40px;
}

.our-solution .solution .right img {
  width: 100%;
  border-radius: 40px;
}


/* .our-solution section.panel .container {
  margin-left: 25%;
} */

.our-solution .solution .left span {
  font-family: Manrope;
  font-size: 24px;
  font-weight: 300;
  line-height: 28.8px;
  letter-spacing: 0.004em;
  text-align: left;
  color: #141616;
  padding: 20px;
  border-radius: 20px;
  background: #E8E8E8;

}

.our-solution .solution .left h2 {
  font-family: Manrope;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0.004em;
  text-align: left;
  color: #141616;
  margin-bottom: 15px;
  margin-top: 35px;

}


.our-solution .solution .left h4 {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.004em;
  text-align: left;
  color: #141616;
  margin-bottom: 15px;

  
}

.our-solution .solution .left p,
.our-solution .solution .left a {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.004em;
  text-align: left;
  color: #141616;
  margin-bottom: 15px;
}

.our-solution .solution .left a {
  text-transform: capitalize;
}

.our-solution .solution .left a img {
  padding-left: 5px;
  width: 28px;
}


@media only screen  and (max-width : 1680px)  {
  
  
  .our-solution .top-section {
    padding-right: 5%;
    padding-left: 5%;
    left: 40%;
    transform: translateX(-60%);
}

.our-solution .solution {
  display: flex;
  gap:30px;
}

  .panel {
    margin-top: 100px;
    height: calc(100vh - 50px);
  }
  .our-solution .solution .left {
    width: 375px;
    width: 800px;
    width: 700px;
    text-align: left;
    background: #F5F5F5;
    border-radius: 40px;
    padding: 25px 22.5px 15px;
    /* padding: 10px 22.5px; */
    /* padding-top: 37.4px; */
    min-height: 460px;
  }

 .our-solution .solution .left span {
    font-size: 18px;
    line-height: 21.6px;
    padding: 15px;
}
  /*
.our-solution .solution .left h2 {
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 11.25px;
  margin-top: 26.25px;
} */

  .our-solution .solution .right {
    width: 472.5px;
    width: 450px;
    border-radius: 40px;
  }

}



@media only screen  and (max-width : 1400px)  {
  
  
.our-solution .top-section {
    padding-right: 5%;
    padding-left: 5%;
    left: 30%;
    transform: translateX(-60%);
}


.our-solution .solution {
  display: flex;
  gap:30px;
}

.our-solution section .container {
  max-width: unset;
}
  .panel {
    margin-top: 100px;
    height: calc(100vh - 30px);
  }
  .our-solution .solution .left {
    width: 1000px;
    min-height: 320px;
  }

  .our-solution .solution .right {
    max-width: 350px;
  }

  
}


/* @media only screen  and (max-width : 1300px)  {
  
  
  .our-solution .top-section {
    padding-right: 5%;
    padding-left: 5%;
    left: 40%;
    transform: translateX(-60%);
}

.our-solution .solution {
  display: flex;
  gap:30px;
}

  .panel {
    margin-top: 100px;
    height: calc(100vh - 50px);
  }
  .our-solution .solution .left {
    width: 375px;
    width: 800px;
    width: 630px;
    text-align: left;
    background: #F5F5F5;
    border-radius: 40px;
    padding: 25px 22.5px 15px;
  }

 .our-solution .solution .left span {
    font-size: 18px;
    line-height: 21.6px;
    padding: 15px;
}
  .our-solution .solution .right {
    width: 472.5px;
    width: 450px;
    border-radius: 40px;
  }

} */

@media only screen  and (max-width : 1300px)  {
  .our-solution .top-section  h2.main-heading {
    font-size: 40px;
    line-height: 40px;
  }

  .our-solution .top-section h4.sub-heading {
    font-size: 20px;
    line-height: 24px;
}
}

/* @media only screen  and (max-width : 1400px)  {
  
  .our-solution .top-section {
    padding-right: 5%;
    padding-left: 5%;
    left: 40%;
    transform: translateX(-60%);
}
  
  .our-solution .solution .left {
    width: 375px;
    width: 600px;
    text-align: left;
    background: #F5F5F5;
    border-radius: 40px;
    padding: 15px 22.5px;
    padding-top: 37.4px;
  }

  .our-solution .solution .left span {
    font-size: 18px;
    line-height: 21.6px;
    padding: 15px;
}

.our-solution .solution .left h2 {
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 11.25px;
  margin-top: 26.25px;
}
  .our-solution .solution .right {
    width: 472.5px;
    width: 500px;
    border-radius: 40px;
  }

} */


@media only screen  and (max-width : 767px)  {

  .our-solution {
    width: 90%;
    margin: auto;
  }

  .our-solution .top-section {
    position: unset;
    text-align: center;
    width: 90%;
    margin: auto;
    padding-right: 0px;
    padding-left: 0px;
    transform: none;
  }

  .our-solution .top-section h2.main-heading{
    width: 70%;
    margin: auto;
    margin-bottom: 10px;
  }

  .our-solution .top-section p {
    font-size: 14px;
    line-height: 16.8px;
  }

  .our-solution .solution {
    flex-direction: column;
    gap:0px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .our-solution .solution .left {
    width: 100%;
    border-radius: 40px 40px 0px 0px;
    padding-top: 50px;   
  }
  
  .our-solution .solution .right,
  .our-solution .solution .right img {
    width: 100%;
    max-width: unset;
    border-radius: 0px 0px 40px 40px;
  }

  .our-solution .solution .left {
    font-size: 20px;
    line-height: 24px;
  }

  .our-solution .solution .left h2 {
    font-size: 30px;
    line-height: 36px;
    padding-top: 20px;
  }

  .our-solution .solution .left h4 {
    font-size: 16px;
    line-height: 22.4px;
  }

  .our-solution .solution .left p,
  .our-solution .solution .left a {
    font-size: 14px;
    line-height: 19.6px;
  }

  
  .our-solution .solution .left a img {
    width: 20px;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .our-solution.mobile {
    display: block;
  } 

  .our-solution.desktop {
    display: none;
  }

  
  .our-solution {
    width: 90%;
    margin: auto;
  }

  .our-solution .top-section {
    position: unset;
    text-align: center;
    width: 90%;
    margin: auto;
    padding-right: 0px;
    padding-left: 0px;
    transform: none;
  }

  .our-solution .top-section h2.main-heading{
    width: 70%;
    margin: auto;
    margin-bottom: 10px;
  }

  .our-solution .top-section p {
    font-size: 14px;
    line-height: 16.8px;
  }

  .our-solution .solution {
    flex-direction: column;
    gap:0px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .our-solution .solution .left {
    width: 100%;
    border-radius: 40px 40px 0px 0px;
    padding-top: 50px;   
  }
  
  .our-solution .solution .right,
  .our-solution .solution .right img {
    width: 100%;
    max-width: unset;
    border-radius: 0px 0px 40px 40px;
  }

  .our-solution .solution .left {
    font-size: 20px;
    line-height: 24px;
  }

  .our-solution .solution .left h2 {
    font-size: 30px;
    line-height: 36px;
    padding-top: 20px;
  }

  .our-solution .solution .left h4 {
    font-size: 16px;
    line-height: 22.4px;
  }

  .our-solution .solution .left p,
  .our-solution .solution .left a {
    font-size: 14px;
    line-height: 19.6px;
  }

  .our-solution .solution .left a img {
    width: 20px;
  }
}