* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
}

html, body {
	overflow-x: hidden;
	margin: 0;
}

.no-scroll {
  overflow-y: hidden;
  height: 100vh;
}

.app {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  position: relative;
}


a {
  text-decoration: none !important;
}


h2.main-heading {
  font-family: Manrope;
  font-size: 80px;
  font-weight: 400;
  line-height: 96px;
  letter-spacing: 0.004em;
  text-align: center;
  color: #141616;
}

h4.sub-heading {
  font-family: Manrope;
  font-size: 32px;
  font-weight: 400;
  line-height: 44.8px;
  letter-spacing: 0.004em;
  text-align: center;
  color: #141616;
}


.button {
  padding: 12px 26px 12px 26px;
  gap: 10px;
  border-radius: 2px;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  background: #EEAE21;
  color: #151522;
  text-transform: capitalize;
}


.blank {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: white;
  z-index: 20;
}


.desktop  {
  display: block;
}

.mobile, .mobile-flex {
  display: none;
}

.modal-book-demo  .modal {
  border: none;
}

.modal-book-demo .modal-header {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  border: none;
}

.modal-book-demo .modal-header .btn-close {
  background: url('./images/close-icon.svg') no-repeat;
  background-size: 40px 40px;

}

.modal-book-demo .modal-content {
  border: none;
}


.modal-book-demo .modal-content .book-demo {
  min-height: unset;
}


@media only screen  and (max-width : 1600px)  {
  h2.main-heading {
    font-size: 60px;
    line-height: 72px;
  }
  
  h4.sub-heading {
    font-size: 24px;
    line-height: 33px;
  }
  
  
  .button {
    padding: 12px 26px 12px 26px;
    gap: 10px;
    border-radius: 2px;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
    background: #EEAE21;
    color: #151522;
  }
}

@media only screen  and (max-width : 767px) {

  .desktop, .desktop-flex {
    display: none;
  }
  
  .mobile {
    display: block;
  }

  .mobile-flex {
    display: flex;
  }

  
h2.main-heading {
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0.004em;
}

h4.sub-heading {
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.004em;
}


.button {
  padding: 12px 21px 12px 21px;
  gap: 10px;
  border-radius: 2px;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  background: #EEAE21;
  color: #151522;
}


}
