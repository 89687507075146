.contact-banner {
    width: 100%;
    min-height: calc( 100vh - 108px );
    position: relative;
    /* background: url('../images/home_banner.webp') no-repeat top center; */
    background-position: top left;
    background-size: cover;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    gap:30px;
    flex-direction: column;
    justify-content: end;
    align-items: flex-start;
    position: relative;
    text-transform: capitalize;
    background: linear-gradient(207.51deg, rgba(0, 0, 0, 0) 42.87%, rgba(0, 0, 0, 0.9) 96.37%), url('../images/contact_banner.webp') no-repeat top center;
}


.contact-banner video#background-video {
  width: 100%;
  position: absolute;
  object-fit: cover;
  height: calc( 100vh - 108px );
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;

}

.contact-banner .text-box {
    margin-bottom: 150px;
}

.contact-banner .text-box h2 {
    font-family: Manrope;
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0.004em;
    text-align: left;
    color:#ffffff;
    margin-bottom: 30px;
}

.contact-banner .text-box h5 {
    font-family: Manrope;
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.004em;
    text-align: left;
    color:#ffffff;
    margin-bottom: 20px;
    width: 80%;
}


/* Scroller styling */
.scroller {
    height: 1.2em;
    line-height: 1.2em;
    height: 140px;
    line-height: 140px;
    position: relative;
    overflow: hidden;
  }

  .scroller > h2 {
    position: absolute;
    top: -25px;
    animation: slide 10s infinite;
    animation-delay: 2s;
    font-weight: bold;
  }
  @keyframes slide {
  
    0% {
      top: -25px;
    }
    30% {
      top: -170px;
    }
    40% {
      top: -170px;
    }
    60% {
      top: -320px;
    }
    
  }



  
@media only screen and (max-width : 768px) {

  .contact-banner {
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 50px;
    padding-bottom: 100px;
    min-height: calc( 100vh - 50px );
    background: linear-gradient(11.69deg, rgba(0, 0, 0, 0) 35.52%, rgba(0, 0, 0, 0.8) 63%), url('../images/contact_banner_mb.webp') no-repeat center center;
    background-size: cover;
}


.contact-banner video#background-video {
  left: unset;
  right: 0;
  object-fit: none;
}


.contact-banner .text-box h2 {
    font-size: 40px;
    line-height: 48px;
}

.contact-banner .text-box h5 {
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 20px;
  width: 100%;
}


}



@media only screen and (min-width: 768px) and (max-width: 1024px) { 

  
  .contact-banner {
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 50px;
    padding-bottom: 150px;
    min-height: calc( 100vh - 50px );
    background: linear-gradient(11.01deg, rgba(0, 0, 0, 0) 30.39%, rgba(0, 0, 0, 0.8) 58.22%), url('../images/whyrepliq_banner_mb.webp') no-repeat center center;  
    background-size: cover;
  }

  .contact-banner .text-box h2 {
    font-size: 50px;
    line-height: 1.2em;
    margin-bottom: 20px;
}


}