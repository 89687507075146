.popup-container {
    padding: 50px 10%;
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    gap:15px;
    max-height: 700px;
    max-height: 100vh;
    overflow: auto;
}

.popup-container img {
    width: 100%;
}

.popup-container h4 {
    font-family: Manrope;
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.004em;
    text-align: left;    
}

.popup-container h5 {
    font-family: Manrope;
    font-size: 32px;
    font-weight: 400;
    line-height: 38.4px;
    letter-spacing: 0.004em;
    text-align: left;
    text-transform: capitalize;
    color: #141616;
}

.popup-container p,
.popup-container ul li {
    font-family: Manrope;
    font-size: 22px;
    font-weight: 400;
    line-height: 28.6px;
    letter-spacing: 0.004em;
    text-align: left;
    color: #141616;
}

.popup-container a.button {
    max-width: 290px;
    max-width: 200px;
    padding: 20px 40px;
}

@media only screen and (max-width : 767px) {

    .popup-container {
        padding: 80px 15px 50px;
        overflow-x: hidden;
    }

    .popup-container img {
        width: 100vw;
        position: relative;
        left: -15px;
    }

    .popup-container h4 {
        font-size: 22px;
        line-height: 26.4px;
    }
    
    .popup-container h5 {
        font-size: 16px;
        line-height: 19.2px;
    }
    
    .popup-container p,
    .popup-container ul li {
        font-size: 14px;
        line-height: 18.2px;
    }
    
    .popup-container a.button {
        font-size: 16px;
        line-height: 22.4px;
        margin: auto;
    }
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {  
    .popup-container {
        max-height: 80vh;
    }
}