.our-solutions {
    font-family: Manrope;
    letter-spacing: 0.004em;
    text-align: left;
    padding-top: 50px;
    padding-bottom: 50px;
}

.our-solutions .solution-wrapper {
    display: flex;
    flex-direction: column;
    gap:50px;
    max-width: 1200px;
    margin: auto;
   
}

.our-solutions .solution-wrapper .our-solution {
    display: flex;
    align-items: flex-start;
    gap:50px;
}

.our-solutions .solution-wrapper .our-solution.reverse {
    flex-direction: row-reverse;
}

.our-solutions .solution-wrapper .our-solution .img-box {
    position: relative;
    align-items: flex-start;
    width: 45%;

}



.our-solutions .solution-wrapper .our-solution .img-box .img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    border-radius: 40px;
}

.our-solutions .solution-wrapper .our-solution .img-box img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 40px;
}

.our-solutions .solution-wrapper .our-solution .img-box .img::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 27%, #000000 67.56%);
    z-index: 1;
}


.our-solutions .solution-wrapper .our-solution .img-box .img-text {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 30px 30px 50px;
    bottom: 0px;
    left: 0px;
    z-index: 2;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 10px;
}

.our-solutions .solution-wrapper .our-solution .img-box .sr {
    border-radius: 0px 0px 40px 0px;
    padding: 15px 20px 25px 0px;
    background: #ffffff;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
}

.our-solutions .solution-wrapper .our-solution .img-box  .sr span {
    border-radius: 20px;
    background: #141616;
    color: #ffffff;
    font-family: Manrope;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    letter-spacing: 0.004em;
    text-align: center;
    width: 80px;
    height: 60px;
    padding: 10px 25px;
}

.our-solutions .solution-wrapper .our-solution .img-box .img-text h2 {
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
}

.our-solutions .solution-wrapper .our-solution .img-box .img-text h4 {
    font-size: 22px;
    font-weight: 600;
    line-height: 30.8px;    
}

.our-solutions .solution-wrapper .our-solution .img-box .img-text p {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
}


.our-solutions .solution-wrapper .our-solution .text-box {
    display: flex;
    flex-direction: column;
    gap: 50px;
    color: #141616;
    position: relative;
    width: 55%;
}

.our-solutions .solution-wrapper .our-solution .text-box  .benefits,
.our-solutions .solution-wrapper .our-solution .text-box  .features {
    background: #F5F5F5;
    padding: 50px;
    border-radius: 40px;
    transition: 0.2s all ease-in;
}


.our-solutions .solution-wrapper .our-solution .text-box .benefits .hidden-text,
.our-solutions .solution-wrapper .our-solution .text-box .features .hidden-text {
    max-height: 0px;
    height: 0px;
    overflow: hidden;
    transition: 0.2s overflow ease-in, 1s max-height 0.2s ease-in, 1s height 0.2s ease-in;
}


.our-solutions .solution-wrapper .our-solution .text-box h4.sub-heading {
    font-size: 32px;
    font-weight: 500;
    line-height: 38.4px;
    text-align: left;
    color:unset;  
}

.our-solutions .solution-wrapper .our-solution .text-box h5 {
    font-size: 24px;
    font-weight: 400;
    line-height: 33.6px;
    text-align: left;
}

.our-solutions .solution-wrapper .our-solution .text-box  ul {
    list-style: none;
    padding-left: 0px;
}

.our-solutions .solution-wrapper .our-solution .text-box p ,
.our-solutions .solution-wrapper .our-solution .text-box ul li {
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: left;
    margin-bottom: 10px;
}

.our-solutions .solution-wrapper .our-solution .text-box .benefits:hover,
.our-solutions .solution-wrapper .our-solution .text-box .features:hover {
    /* transition: 0.2s all ease-in; */
    color:#ffffff;
    background: #000000;
    cursor: pointer;
}

.our-solutions .solution-wrapper .our-solution .text-box .benefits:hover .hidden-text,
.our-solutions .solution-wrapper .our-solution .text-box .features:hover .hidden-text {
    overflow: unset;
    max-height: unset;
    height: auto;
    /* transition: 0.5s all ease-in; */
}

.our-solutions .solution-wrapper .our-solution .text-box .benefits:hover h5,
.our-solutions .solution-wrapper .our-solution .text-box .features:hover h5 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
}


.our-solutions .solution-wrapper .our-solution .text-box  .benefits:hover ~ .features,
.our-solutions .solution-wrapper .our-solution .text-box  .features:hover + .benefits {
    /* transition: 0.2s all ease-in; */
    display: none;
}


.our-solutions .solution-wrapper .our-solution .text-box  .features:hover {
    /* transition: 0.2s all ease-in; */
    position: absolute;
    top: 0px;;
}   

@media only screen and (max-width : 767px)  {

    .our-solutions .solution-wrapper {
        gap:0px;
    }

    .our-solutions .solution-wrapper .our-solution {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap:25px 0px;
        padding: 25px 0px;
    }
    
    .our-solutions .solution-wrapper .our-solution.reverse {
        flex-direction: column;
    }
    
    
    .our-solutions .solution-wrapper .our-solution .img-box ,
    .our-solutions .solution-wrapper .our-solution .text-box{
        width: 100%;
    }  

    .our-solutions .solution-wrapper .our-solution .text-box {
        gap: 25px;
    }

    /* .our-solutions .solution-wrapper .our-solution .text-box  .benefits,
    .our-solutions .solution-wrapper .our-solution .text-box  .features,
    .our-solutions .solution-wrapper .our-solution .text-box  .benefits:hover,
    .our-solutions .solution-wrapper .our-solution .text-box  .features:hover {
        background: #F5F5F5;
        color: #141616;
        padding: 15px;
    } */

    .our-solutions .solution-wrapper .our-solution .text-box  .benefits:hover ~ .features,
    .our-solutions .solution-wrapper .our-solution .text-box  .features:hover + .benefits {
    /* transition: 0.2s all ease-in; */
        display: block;
    }

    .our-solutions .solution-wrapper .our-solution .text-box  .features:hover {
        /* transition: 0.2s all ease-in; */
        position: inherit;
        top: 0px;
    }   
    
/* 
    .our-solutions .solution-wrapper .our-solution .text-box .benefits .hidden-text,
    .our-solutions .solution-wrapper .our-solution .text-box .features .hidden-text {
        overflow: unset;
        max-height: unset;
        height: auto;
    } */


    .our-solutions .solution-wrapper .our-solution .img-box .img-text {
        padding: 15px 15px 15px;
        gap:0px;
    }

    .our-solutions .solution-wrapper .our-solution .img-box  .sr span {
        font-size: 28px;
        line-height: 21.6px;
        width: 60px;
        height: 40px;
        padding: 10px 25px;
    }
    
    .our-solutions .solution-wrapper .our-solution .img-box .img-text h2 {
        font-size: 22px;
        line-height: 26.2px;
    }
    
    .our-solutions .solution-wrapper .our-solution .img-box .img-text h4 {
        font-size: 16px;
        line-height: 22.4px;    
    }
    
    .our-solutions .solution-wrapper .our-solution .img-box .img-text p {
        font-size: 14px;
        line-height: 19.6px;
    }
        
    .our-solutions .solution-wrapper .our-solution .text-box h4.sub-heading {
        font-size: 30px;
        line-height: 36px;
    }

    .our-solutions .solution-wrapper .our-solution .text-box h5 ,
    .our-solutions .solution-wrapper .our-solution .text-box .benefits:hover h5,
    .our-solutions .solution-wrapper .our-solution .text-box .features:hover h5{
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
    }

    .our-solutions .solution-wrapper .our-solution .text-box  ul {
        list-style: none;
        padding-left: 0px;
    }

    .our-solutions .solution-wrapper .our-solution .text-box p ,
    .our-solutions .solution-wrapper .our-solution .text-box ul li {
        line-height: 19.6px;
    }


    .our-solutions .solution-wrapper .our-solution .text-box .benefits,
    .our-solutions .solution-wrapper .our-solution .text-box .features {
        position: relative;
        padding: 30px 50px 15px;
    }    

    .our-solutions .solution-wrapper .our-solution .text-box img.plus-icon {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 20px;
        height: 20px;
        border: 1px solid rgb(0, 0, 0);
        border-radius: 50px;
        padding: 3px;
        background: #ffffff;
    }


    .our-solutions .solution-wrapper .our-solution .text-box .benefits img.plus-icon:hover + .hidden-text,
    .our-solutions .solution-wrapper .our-solution .text-box .features  img.plus-icon:hover + .hidden-text {
        overflow: visible;
        max-height: unset;
        height: auto;
    } 

    
}



@media only screen and (min-width: 768px) and (max-width: 1024px) { 
    .our-solutions .solution-wrapper {
        gap:0px;
    }

    .our-solutions .solution-wrapper .our-solution {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap:25px 0px;
        padding: 25px 0px;
    }
    
    .our-solutions .solution-wrapper .our-solution.reverse {
        flex-direction: column;
    }
    
    
    .our-solutions .solution-wrapper .our-solution .img-box ,
    .our-solutions .solution-wrapper .our-solution .text-box{
        width: 100%;
    }  

    .our-solutions .solution-wrapper .our-solution .text-box {
        gap: 25px;
    }

    .our-solutions .solution-wrapper .our-solution .text-box  .benefits,
    .our-solutions .solution-wrapper .our-solution .text-box  .features,
    .our-solutions .solution-wrapper .our-solution .text-box  .benefits:hover,
    .our-solutions .solution-wrapper .our-solution .text-box  .features:hover {
        background: #F5F5F5;
        color: #141616;
        padding: 15px;
    }

    .our-solutions .solution-wrapper .our-solution .text-box  .benefits:hover ~ .features,
    .our-solutions .solution-wrapper .our-solution .text-box  .features:hover + .benefits {
    /* transition: 0.2s all ease-in; */
        display: block;
    }

    .our-solutions .solution-wrapper .our-solution .text-box  .features:hover {
        /* transition: 0.2s all ease-in; */
        position: inherit;
        top: 0px;
    }   
    

    .our-solutions .solution-wrapper .our-solution .text-box .benefits .hidden-text,
    .our-solutions .solution-wrapper .our-solution .text-box .features .hidden-text {
        overflow: unset;
        max-height: unset;
        height: auto;
    }


    .our-solutions .solution-wrapper .our-solution .img-box .img-text {
        padding: 15px 15px 15px;
        gap:0px;
    }

    .our-solutions .solution-wrapper .our-solution .img-box  .sr span {
        font-size: 28px;
        line-height: 21.6px;
        width: 60px;
        height: 40px;
        padding: 10px 25px;
    }
    
    .our-solutions .solution-wrapper .our-solution .img-box .img-text h2 {
        font-size: 22px;
        line-height: 26.2px;
    }
    
    .our-solutions .solution-wrapper .our-solution .img-box .img-text h4 {
        font-size: 16px;
        line-height: 22.4px;    
    }
    
    .our-solutions .solution-wrapper .our-solution .img-box .img-text p {
        font-size: 14px;
        line-height: 19.6px;
    }
        
    .our-solutions .solution-wrapper .our-solution .text-box h4.sub-heading {
        font-size: 30px;
        line-height: 36px;
    }

    .our-solutions .solution-wrapper .our-solution .text-box h5 ,
    .our-solutions .solution-wrapper .our-solution .text-box .benefits:hover h5,
    .our-solutions .solution-wrapper .our-solution .text-box .features:hover h5{
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
    }

    .our-solutions .solution-wrapper .our-solution .text-box  ul {
        list-style: none;
        padding-left: 0px;
    }

    .our-solutions .solution-wrapper .our-solution .text-box p ,
    .our-solutions .solution-wrapper .our-solution .text-box ul li {
        line-height: 19.6px;
    }

}
