.about-redefining {
    position: relative;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  
  .about-redefining .top-section {
    text-align: center;
    width: 80%;
    margin: auto;
  }

  
  .about-redefining .top-section h4 {
    font-family: Manrope;
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.004em;
    text-align: center;
    color: #000000;
  }
  
  
  .scroll-wrapper {
    overflow-x: hidden;
    display: flex;
    position: sticky;
    padding-left: 10%;
    top: 300px;
  }
    
  h1 {
    margin: 0;
    padding: 0;
  }
  
  .about-redefining .container {
      margin: auto;
  }
   
  .about-redefining .solution {
    display: flex;
    gap:50px;
    padding: 50px 15px 30px;
  }
  
  .about-redefining .solution .left {
    width: 60%;
    border-radius: 40px;
    overflow: hidden;
  }
    
  .about-redefining .solution .right {
    width: 40%;
  }
  
  .about-redefining .solution .left img {
    width: 100%;
    transition: all .5s ease-in;
    cursor: pointer;
    /* border-radius: 40px; */
  }

  .about-redefining .solution .left img:hover {
    transform: scale(1.5);
  }

  .about-redefining .solution p {
    font-family: Manrope;
    font-size: 22px;
    font-weight: 400;
    line-height: 28.6px;
    letter-spacing: 0.004em;
    text-align: justify;
    color: #141616;
  }
  
  /* section {
    min-width: 50vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4ch;
  }
    
  section:nth-child(even) {
    background-color: teal;
    color: white;
  }
     */
    

@media only screen and (max-width : 767px)  {

  .about-redefining {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .about-redefining .top-section h4 {
    font-size: 22px;
    line-height: 30.8px;
  }

  .about-redefining .solution {
    flex-direction: column;
  }
  .about-redefining .solution .left,
  .about-redefining .solution .right {
    width: 100%;
  }

  .about-redefining .solution p {
    font-size: 18px;
    line-height: 25.2px;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {  
  .about-redefining {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .about-redefining .top-section h4 {
    font-size: 22px;
    line-height: 30.8px;
  }

  .about-redefining .solution {
    flex-direction: column;
  }
  .about-redefining .solution .left,
  .about-redefining .solution .right {
    width: 100%;
  }

  .about-redefining .solution p {
    font-size: 18px;
    line-height: 25.2px;
    text-align: center;
  }
}