.solutions-banner {
    width: 100%;
    min-height: calc( 100vh - 108px );
    position: relative;
    background:linear-gradient(248.26deg, rgba(0, 0, 0, 0) 18.18%, rgba(0, 0, 0, 0.9) 84.47%), url('../images/solutions_banner.webp') no-repeat top;
    background-position: top left;
    background-size: cover;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 10%;
    display: flex;
    gap:30px;
    flex-direction: column;
    justify-content: end;
    align-items: flex-start;
    position: relative;
    text-transform: capitalize;
}


.solutions-banner video#background-video {
  width: 100%;
  position: absolute;
  object-fit: cover;
  height: calc( 100vh - 108px );
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
}


.solutions-banner .text-box h2 {
    font-family: Manrope;
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0.004em;
    text-align: left;
    color:#ffffff;  
}

.solutions-banner .text-box h3 {
    font-family: Manrope;
    font-size: 60px;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: 0.004em;
    text-align: left;
    color:#ffffff;  
    margin-bottom: 20px;
}

  
@media only screen  and (max-width : 767px)  {

  .solutions-banner {
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 50px;
    padding-bottom: 150px;
    padding-left: 7%;
    padding-right: 7%;
    min-height: calc( 100vh - 50px );
    background: linear-gradient(15.04deg, rgba(0, 0, 0, 0) 16.7%, rgba(0, 0, 0, 0.8) 41.1%), url('../images/solutions_banner.webp') no-repeat center center;  
    background-size: cover;

}


.solutions-banner video#background-video {
  left: unset;
  right: 0;
  object-fit: none;
}


.solutions-banner .text-box h2 {
    font-size: 40px;
    line-height: 48px;
    text-align: left;
    margin-bottom: 15px;
}

.solutions-banner .text-box h3 {
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 20px;
}


}




@media only screen and (min-width: 768px) and (max-width: 1024px) { 

  
  .solutions-banner {
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 50px;
    padding-bottom: 150px;
    min-height: calc( 100vh - 108px );
    background: linear-gradient(15.04deg, rgba(0, 0, 0, 0) 16.7%, rgba(0, 0, 0, 0.8) 41.1%), url('../images/solutions_banner.webp') no-repeat center center;  
    background-size: cover;
  }

  .solutions-banner .desktop {
    display: block;
  }

  .solutions-banner .mobile {
    display: none;
  }

  .solutions-banner .text-box h2 {
    font-size: 50px;
    line-height: 1.2em;
    margin-bottom: 20px;
}

.solutions-banner .text-box h3 {
    font-size: 30px;
    line-height: 1.2em;
    margin-bottom: 15px;
    width: 90%;
}


}