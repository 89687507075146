.misson-vision {
    padding: 50px 0px;    
}


.misson-vision .mv-boxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    width: 100%;
}

.misson-vision .mv-boxes .mv-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F7F7F7;
    border-radius: 40px;
    padding: 50px 25px;
    min-height: 400px;
    max-height: 400px;
    transition: all .5s ease-in;
    position: relative;
}

/* .misson-vision .mv-boxes .mv-box:hover {
    padding:50px 25px;
} */

.misson-vision .mv-boxes .mv-box img {
    width: 100px;
    transition: all .5s ease-in;
}


.misson-vision .mv-boxes .mv-box h3 {
    font-family: Manrope;
    font-size: 60px;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: 0.004em;
    text-align: center;
    color: #141616;
    /* transition: all .5s ease-in; */
}

/* .misson-vision .mv-boxes .mv-box:hover h3 {
    font-size: 40px;
    line-height: 48px;
} */

.misson-vision .mv-boxes .mv-box .textbox {
    background:#ffffff;
    padding: 30px;
    border-radius: 40px;
    width: 85%;
    margin: auto;
    text-align: center;
    box-shadow: 0px 1px 6px 0px #0000000F;  
}

.misson-vision .mv-boxes .mv-box.mission .textbox {
    padding: 10px 15px;
}
    

.misson-vision .mv-boxes .mv-box .textbox p {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #000000;
}

.misson-vision .mv-boxes .mv-box .textbox {
    position: absolute;
    opacity: 0;
    top:180px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 1px 6px 0px #0000000F;
    transition:0.3s all 0.2s ease-in;
    transition: 0.5s all ease-in;
}

.misson-vision .mv-boxes .mv-box.mission .textbox {
    top:150px;
}

.misson-vision .mv-boxes .mv-box:hover .textbox {
    opacity: 1;
    transition:0.3s all ease-in;
    transition: 0.5s all ease-in;
}

.misson-vision .mv-boxes .mv-box .img-box {
    text-align: center;
    transition: 0.5s all ease-in;
}
.misson-vision .mv-boxes .mv-box:hover .img-box {
    transform: translateY(-100px) scale(0.65);
    transition: 0.5s all ease-in;
}

.misson-vision .mv-boxes .mv-box.mission:hover .img-box {
    transform: translateY(-100px) scale(0.55);
    transition: 0.5s all ease-in;
}


@media only screen and (max-width : 767px)  {
    .misson-vision .mv-boxes {
        grid-template-columns: 1fr;
        gap:25px;
    }

    .misson-vision .mv-boxes .mv-box {
        padding: 50px 25px;
        min-height: unset;
        gap:20px
    }

    .misson-vision .mv-boxes .mv-box img {
        width: 40px;
    }


    .misson-vision .mv-boxes .mv-box:hover .img-box {
        transform: none;
    }
    
    .misson-vision .mv-boxes .mv-box.mission:hover .img-box {
        transform: none;
    }
    

    .misson-vision .mv-boxes .mv-box h3 {
        font-size: 22.11px;
        line-height: 26.53px;
    }

    .misson-vision .mv-boxes .mv-box .textbox {
        position: inherit;
        top: unset;
        left: unset;
        transform: unset;
        opacity: 1;
        padding: 15px 15px 0px;
        border-radius: 40px;
        width: 100%;
        box-shadow: 0px 1px 8px 0px #0000001F;
    }

    .misson-vision .mv-boxes .mv-box.mission .textbox {
        top: unset;
    }

    .misson-vision .mv-boxes .mv-box .textbox p {
        font-size: 14px;
        line-height: 19.6px;
    }

}



@media only screen and (min-width: 768px) and (max-width: 1024px) {  
    .misson-vision .mv-boxes {
        grid-template-columns: 1fr;
        gap:25px;
    }

    .misson-vision .mv-boxes .mv-box {
        padding: 50px 25px;
        min-height: unset;
        gap:20px
    }

    .misson-vision .mv-boxes .mv-box img {
        width: 40px;
    }

    .misson-vision .mv-boxes .mv-box h3 {
        font-size: 22.11px;
        line-height: 26.53px;
    }

    .misson-vision .mv-boxes .mv-box .textbox {
        position: inherit;
        top: unset;
        left: unset;
        transform: unset;
        opacity: 1;
        padding: 15px 15px 0px;
        border-radius: 40px;
        width: 75%;
        box-shadow: 0px 1px 8px 0px #0000001F;
    }

    .misson-vision .mv-boxes .mv-box.mission .textbox {
        top: unset;
    }

    .misson-vision .mv-boxes .mv-box .textbox p {
        font-size: 14px;
        line-height: 19.6px;
    }

}