.cta {
    padding: 60px 15px 60px;
    border-top:1px solid #262626;
    border-bottom:1px solid #262626;
    background-color: #292929;
    background-image: url('../images/footer-cta.webp');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.cta .cta-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 100px;
    padding-right: 100px;
}

.cta .cta-box div:first-child {
    width: calc(100% - 200px);
}

.cta .cta-box div:last-child {
    width: 200px;
}

.cta .cta-box h3 {
    font-family: Manrope;
    font-size: 38px;
    font-weight: 600;
    line-height: 57px;
    text-align: left;
    color:#ffffff;
    text-transform: capitalize;
}

.cta .cta-box p {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #999999;
}

section.footer {
    background: #0F0F0F;
}

.footer .flex-box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 100px;
    padding-bottom: 50px;
    display: grid;
    grid-template-columns: 20% 50% 12% 30%;
}

.footer .flex-box .logo,
.footer .flex-box .logo img {
    max-width: 144px;
}

.footer .flex-box .space {
    max-width: 450px;
    width: 100%;
}

.footer .flex-box .links {
    float: right;
}

.footer .flex-box .links h2 {
    /* font-family: Darker Grotesque; */
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.72px;
    text-align: left;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.footer .flex-box .links ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding-left: 0px;
}

.footer .flex-box .links ul li a {
    /* font-family: Work Sans; */
    font-family: Manrope;
    font-size: 15px;
    font-weight: 400;
    line-height: 27.3px;
    text-align: left;
    color: #848895;
    text-decoration: none;
}

.footer .copyright {
    background: #000000;
    padding: 12px 10px 12px 10px;
}

.footer .copyright .cr-flex {
    display: flex;
    justify-content: space-between;
   
}

.footer .copyright .cr-flex .left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.footer .copyright .cr-flex .left p,
.footer .copyright .cr-flex .left a {
    /* font-family: Urbanist; */
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.006em;
    text-align: left;
    color: #ffffff;
    margin-bottom: 0px;
    text-decoration: none;
}

.footer .copyright .cr-flex .left a {
    font-weight: 600;
    font-size: 16px;
}

.footer .copyright .cr-flex .right ul.social-icons {
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    gap: 15px;
}

.footer .copyright .cr-flex .right .icon-box {  
    width:  40px;
    height: 40px;
    border-radius: 60px;
    background: #141414;
    padding: 7px 10px;
}

.footer .copyright .cr-flex .right .icon-box img {
    width: 20px;
    height: 20px;
}


@media only screen  and (max-width : 768px)  {

    .cta {
        padding: 60px 15px 60px;
        background-color: #292929;
        background-position: center center;
    }
    
    .cta .cta-box {
        flex-direction: column;
        padding-left: 0px;
        padding-right: 0px;
        gap:20px;
    }
    
    .cta .cta-box div:first-child ,
    .cta .cta-box div:last-child {
        width: 100%;
        text-align: center;
    }

    .cta .cta-box h3 {
        font-size: 28px;
        line-height: 1.2em;
        text-align: center;
    }
    
    .cta .cta-box p {
        font-size: 14px;
        line-height: 1.2em;
        text-align: center;
    }
    

    .footer .flex-box {
         display: grid;
        grid-template-columns: 50% 50%;
        width: 95%;
        margin: auto;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .footer .flex-box .logo,
    .footer .flex-box .logo img {
        max-width: 144px;
    }

    .footer .flex-box .links {
        float: unset;
        padding-left: 50px;
    }

    
.footer .flex-box .links h2 {
    /* font-family: Darker Grotesque; */
    font-family: Manrope;
    font-size: 14px; 
    line-height: 18.72px;   
    margin-bottom: 20px;
}

.footer .flex-box .links ul li a {
    /* font-family: Work Sans; */
    font-family: Manrope;
    font-size: 14px;
    line-height: 27.3px;    
}

.footer .copyright, 
.footer .copyright .container {
    padding-left: 0px;
    padding-right: 0px;
}


.footer .copyright .cr-flex {
    flex-direction: column;
}

.footer .copyright .cr-flex .right {
    order: -1;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #4A4740;
}

.footer .copyright .cr-flex .left {
    padding: 20px;
}


.footer .copyright .cr-flex .left p, .footer .copyright .cr-flex .left a {
    font-size: 12px;
    line-height: 24px;

}


}


@media only screen and (min-width: 768px) and (max-width: 1024px) { 
    .cta {
        padding: 60px 15px 60px;
        background-color: #292929;
        background-position: center center;
    }
    
    .cta .cta-box {
        flex-direction: column;
        padding-left: 0px;
        padding-right: 0px;
        gap:20px;
    }

    .cta .cta-box div:first-child ,
    .cta .cta-box div:last-child {
        width: 100%;
        text-align: center;
    }


    
    .cta .cta-box h3 {
        font-size: 28px;
        line-height: 1.2em;
        text-align: center;
    }
    
    .cta .cta-box p {
        font-size: 14px;
        line-height: 1.2em;
        text-align: center;
    }   
}