.difference {
    padding-top: 100px;
}

.difference .main-heading ,
.difference .sub-heading {
    padding-bottom: 25px;
}

.difference .hover-boxes {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    gap:50px;
    padding-bottom: 50px;
}

.difference .hover-box {
    position: relative;
    border-radius: 40px;
    overflow: hidden;
    cursor: pointer;
}

.difference .hover-box:hover .hover-img {
    transform: scale(1.5);
}

.difference .hover-boxes .hover-img {
    width: 100%;
    transition: all .5s ease-in;
}

.difference .hover-box h4.title {
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
}

.difference .hover-box .hover-text {
    position: absolute;
    bottom: 50px;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    background: #ffffff;
    padding: 35px 25px;
    border-radius: 40px;
    transition: all .5s ease-in;
    opacity: 0;
    visibility: hidden;
}

.difference .hover-box:hover .hover-text {
    opacity: 1  ;
    visibility: visible;
}


.difference .hover-box h4 {
    font-family: Manrope;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0.004em;
    text-align: center;
}

.difference .hover-box .hover-text h4 {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: center;
}

.difference .hover-box .hover-text a {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: center;
    float: right;
    color:#000000;
    text-transform: capitalize;
}

.difference .hover-box .hover-text a img {
    padding-left: 20px;
}

.difference a.button {
    font-size: 20px;
    line-height: 14px;
    padding: 20px 40px;
}



@media only screen and (max-width : 768px)  
{
    .difference {
        padding-top: 50px;
    }

    .difference .hover-boxes {
        display: grid;
        grid-template-columns: 100%;
        justify-content: center;
        gap:50px;
        padding-bottom: 30px;
    }

    .difference .hover-box .hover-text {
        width: 90%;
        opacity: 0;
        visibility: hidden;
        bottom: 40px;
    }

    .difference .hover-box:hover .hover-text { 
        opacity: 0;
        /* visibility: visible; */
    }

    .difference .hover-box,
    .difference .hover-box .hover-text { 
        border-radius: 22.11px;
    }
    
    .difference .hover-box {
        position: relative;
    }

    .difference .hover-box .plus-icon {
        position: absolute;
        bottom: 15px;
        right: 15px;
        width: 20px;
        height: 20px;
        border: 1px solid rgb(0, 0, 0);
        border-radius: 50px;
        padding: 3px;
        background: #ffffff;
    }


    .difference .hover-box img.plus-icon:hover + .hover-text {
        opacity: 1;
        visibility: visible;
    }
    .difference .hover-box:hover .hover-img {
        transform: none;
    }

    .difference .hover-box h4 {
        font-size: 22.11px;
        line-height: 26.53px;        
     }

     
     .difference .hover-box .hover-text h4,
     .difference .hover-box .hover-text a {
        font-size: 14px;
        line-height: 19.6px;
    }
    

    .difference a.button {
        font-size: 20px;
        line-height: 14px;
        text-align: center;
    }
}